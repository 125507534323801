<template>
  <div class="OnboardingStep1">
    <div class="title has-text-centered p-v-l is-4">
      {{ $t(`pages.onboarding.step_1.cover_text`) }}
    </div>

    <form @submit.prevent="submit">
      <form-field
        :validator="validator.newsroomName"
        :label="$t('forms.newsroom_name')"
        :server-errors="nameErrorMessages"
      >
        <input
          v-model.trim="newsroomName"
          :placeholder="$t('forms.name_your_newsroom')"
          class="input"
          type="text"
          name="newsroomName"
          data-testid="OnboardingStep1_InputNewsroomName"
          @change="handleInput"
        >
      </form-field>

      <form-field
        :validator="validator.website"
        :label="$t('forms.what_is_your_website')"
        :attribute="$t('forms.website')"
      >
        <input
          v-model.trim="website"
          placeholder="www.hypefactors.com"
          class="input"
          type="text"
          data-testid="OnboardingStep1_InputWebsite"
          name="website"
          @input="validator.website.$touch()"
        >
      </form-field>

      <form-field
        :validator="validator.countryIso2"
        :label="$t('forms.country')"
        :tooltip="$t('pages.onboarding.step_1.tooltip_country')"
      >
        <country-picker
          :value="countryIso2"
          data-testid="OnboardingStep1_InputCountry"
          @input="selectLanguageFromCountry"
        />
      </form-field>

      <form-field
        :validator="validator.language"
        :label="$t('forms.language')"
        :tooltip="$t('pages.onboarding.step_1.tooltip_language')"
      >
        <language-picker
          v-model="language"
          show-supported-only
          data-testid="OnboardingStep1_InputLanguage"
        />
      </form-field>
    </form>

    <div class="has-text-right OnboardingStep__actions">
      <v-button
        :disabled="nameErrorMessages.length > 0"
        :loading="isCheckingName"
        class="is-primary is-width-1/2-mobile"
        data-testid="OnboardingStep1_NextButton"
        @click="submit"
      >
        {{ $t('general.next') }}
      </v-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'
import OnboardingStepPrototype, { createComputedFormField } from '../OnboardingStepPrototype'

export default {
  name: 'OnboardingStep1',
  extends: OnboardingStepPrototype,

  data () {
    return {
      nameErrorMessages: [],
      isCheckingName: false
    }
  },

  computed: {
    ...mapGetters(['getCountryByIso2', 'languages']),

    language: createComputedFormField('language'),
    countryIso2: createComputedFormField('countryIso2'),
    newsroomName: createComputedFormField('newsroomName'),
    website: createComputedFormField('website')
  },

  methods: {
    handleInput () {
      this.validator.newsroomName.$touch()
      this.nameErrorMessages = []
      this.checkForNewsroomNameUniqueness()
    },

    async submit () {
      const isValid = this.validate()

      if (!isValid) return

      const isTaken = await this.checkForNewsroomNameUniqueness()

      if (!isTaken) {
        this.goNext()
      }
    },

    async checkForNewsroomNameUniqueness () {
      if (!this.newsroomName) return

      this.isCheckingName = true
      let isTaken = false

      try {
        isTaken = await NewsroomService.isNameTaken(this.newsroomName, this.countryIso2)
      } catch (error) {
        isTaken = true
      }

      if (isTaken) {
        this.nameErrorMessages = [this.$t('validations.newsroom_name_is_taken')]
      }

      this.isCheckingName = false

      return isTaken
    },

    /**
     * Called when the CountryPicker component changes
     */
    async selectLanguageFromCountry (countryIso2) {
      const country = this.getCountryByIso2(countryIso2)

      // If there is not a language with the same lang iso, we skip setting it
      if (this.languages.findIndex(l => l.code === country.language) === -1) {
        this.countryIso2 = countryIso2
        await this.$nextTick()
        this.handleInput()
        return
      }

      this._sendToParent({
        countryIso2: countryIso2,
        language: country.language
      })
      await this.$nextTick()
      this.handleInput()
    }
  }
}
</script>
